import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Container from "../components/container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SmallYellowBlob from "../components/small-yellow-blob"
import FinalCTA from "../components/final-cta"
import dayjs from "dayjs"
import Seo from "../components/seo"
import BlogPostCard from "../components/blog-post-card"
import EmailSignup from "../components/email-signup"
import PrismicBlogPostCard from "../components/prismic-blog-post-card"
import MasterClassEmailSignup from "../components/master-class-email-signup"
import PuppyEmailSignup from "../components/puppy-email-signup"

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { author } = pageContext // data.markdownRemark holds your post data
  const { recommended } = pageContext // data.markdownRemark holds your post data
  const { full_name_and_title, page } = author
  const { frontmatter, html } = markdownRemark
  const { title, featured_image, image_alt_text, category, posting_date } =
    frontmatter
  const authorImage = getImage(author.author_image)
  const image = getImage(featured_image)

  return (
    <Layout>
      <div
        className="border-l-[10px] border-r-[10px] lg:border-l-[60px] lg:border-r-[60px] border-t-[30px] border-b-[30px]  border-lighterPrimary overflow-x-clip"
        style={{ zIndex: 1 }}
      >
        <article className="prose prose-headings:text-lightBlack prose-h3:font-crimson prose-h3:normal-case prose-h3:tracking-normal prose-a:text-primary max-w-none mx-auto prose-img:rounded-lg">
          <Container>
            <div className="relative not-prose">
              <div
                className="absolute top-[0%] lg:top-[5%] left-[0%] lg:left-[10%] w-[100%] lg:w-[80%] h-[100%] lg:h-[110%]"
                style={{ zIndex: 0 }}
              >
                <SmallYellowBlob />
              </div>
              <div
                className="pt-6 lg:pt-24 px-2 lg:px-0 pb-0 max-w-3xl  mx-auto relative"
                style={{ zIndex: 30 }}
              >
                <h1 className="mb-2 lg:mb-4 mt-2 normal-case text-3xl lg:text-5xl tracking-normal font-semibold text-lightBlack">
                  {title}
                </h1>
                <hr className="border border-primary max-w-lg mb-2 lg:mb-4" />

                <p className="uppercase font-medium text-sm lg:text-md text-gray-500">
                  <span>{category}</span> | By:{" "}
                  <Link
                    to={page ? page : undefined}
                    itemProp="author"
                    name={full_name_and_title}
                  >
                    <span
                      className={`text-gray-500 ${page && "hover:underline"}`}
                    >
                      {full_name_and_title}
                    </span>
                  </Link>{" "}
                  | <span>{dayjs(posting_date).format("MMM DD, YYYY")}</span>
                </p>
                <div className="rounded-lg mt-4 lg:mt-12 mx-auto text-center">
                  <GatsbyImage
                    image={image}
                    // width={"100%"}
                    // hieght={"150px"}
                    className={"rounded-lg"}
                    alt={image_alt_text}
                  />
                </div>
              </div>
            </div>
          </Container>
          <Container>
            {category === "Reactivity" ? (
              <MasterClassEmailSignup />
            ) : (
              <PuppyEmailSignup />
            )}
          </Container>

          <Container>
            <div
              className="text-lightBlack mt-8 mb-16 max-w-xl mx-auto relative px-4 xl:px-0"
              style={{ zIndex: 11 }}
            >
              <div
                className="hidden md:block  absolute bottom-[20%] -left-[40%] lg:-left-[85%] w-[100%] rotate-[150deg]	 "
                style={{ zIndex: 0 }}
              >
                {/* <SmallYellowBlob /> */}
              </div>
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
          </Container>
          {/* Final CTA */}
        </article>
        <Container>
          <div
            className="flex relative not-prose items-center justify-center mt-4 mb-24 mx-2"
            // style={{ zIndex: 30 }}
          >
            <div className="">
              {/* <StaticImage
                src={author.}
                className="z-10"
                alt="Taylor and Scott high fiving after some great dog training in Boise"
                layout="constrained"
                width={120}
                height={120}
              /> */}
              <GatsbyImage
                image={authorImage}
                layout="fixed"
                width={120}
                height={120}
                className={"rounded-lg"}
                alt={`Profile picture of ${full_name_and_title}`}
              />
            </div>
            <div className="flex flex-col ml-6">
              <h3 className="mb-0 te font-medium">Written By</h3>
              <Link to={page ? page : undefined}>
                <h2 className=" text-xl sm:text-3xl mb-0 text-lightBlack">
                  {full_name_and_title}
                </h2>
              </Link>
              <p className="max-w-sm">{author.about}</p>
            </div>
          </div>
        </Container>
        {recommended && (
          <Container>
            <h2 className=" text-xl sm:text-4xl mb-6 text-lightBlack text-center">
              Also worth your time
            </h2>

            <div className="flex not-prose flex-wrap justify-between pt-6 mb-6 max-w-5xl mx-auto">
              {recommended.map(rec => {
                return Object.keys(rec).includes("frontmatter") ? (
                  <BlogPostCard
                    key={rec.slug}
                    data={rec.frontmatter}
                    isRecommended={true}
                  />
                ) : (
                  <PrismicBlogPostCard
                    key={rec.slug}
                    prismicData={{ ...rec.data, url: rec.slug }}
                    isRecommended={true}
                  />
                )
              })}
            </div>
            <div className="text-center mb-24">
              <Link className=" text-center underline text-xl" to={"/tips/"}>
                See All Posts
              </Link>
            </div>
          </Container>
        )}
        <Container>
          {category === "Reactivity" ? (
            <MasterClassEmailSignup />
          ) : (
            <PuppyEmailSignup />
          )}
        </Container>
      </div>
      {/* Final CTA */}
      <FinalCTA />
    </Layout>
  )
}

export const Head = ({ data, location, pageContext }) => {
  const {
    markdownRemark: {
      frontmatter: { title, posting_date, featured_image },
    },
  } = data

  return (
    <Seo
      title={title}
      pathname={location.pathname}
      isBlogPost={true}
      datePublished={posting_date}
      image={featured_image?.publicURL}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          headline: title,
          image: [
            "https://scottsschoolfordogs.com" + featured_image?.publicURL,
          ],
          datePublished: posting_date,
          dateModified: posting_date,
          author: [
            {
              "@type": "Person",
              name: pageContext.author.full_name_and_title.split(",")[0],
              honorificSuffix:
                pageContext.author.full_name_and_title.split(",")[1],
              url: "https://scottsschoolfordogs.com" + pageContext.author.page,
            },
          ],
        })}
      </script>
    </Seo>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title

        featured_image {
          childImageSharp {
            gatsbyImageData(height: 500, placeholder: BLURRED)
          }
          publicURL
        }
        image_alt_text
        is_active
        posting_date
        category
      }
    }
  }
`
